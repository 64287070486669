<template>
  <div>
    <span v-if="outside" class="select-outside-label">
      {{ label }}
    </span>
    <v-select
      v-model="selected"
      class="custom-select"
      item-text="name"
      :item-value="itemValue"
      :label="outside ? '' : label"
      :items="items"
      return-object
      append-icon="keyboard_arrow_down"
      :rules="rules"
      outlined
      @input="$emit('input', selected.id)"
    >
      <template v-for="(_, name) in $slots" v-slot:[name]>
        <slot :name="name" />
      </template>
      <template v-slot:append>
        <v-icon
          :class="arrowClass"
          small
        >
          keyboard_arrow_down
        </v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-icon
          class="dot-icon"
          :class="`${item.name}-dot`"
        >
          fiber_manual_record
        </v-icon>
      </template>
      <template v-slot:selection="{ item }">
        <v-icon
          class="dot-icon"
          :class="`${item.name}-dot`"
        >
          fiber_manual_record
        </v-icon>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'ColorSelect',
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: undefined,
    },
    rules: {
      type: Array,
      default: undefined,
    },
    outside: Boolean,
    arrowClass: {
      type: String,
      default: 'white-icon',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  watch: {
    value(val) {
      if (!val) {
        this.selected = 1;
      } else {
        this.selected = val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .select-outside-label {
    display: block;
    color: $grey;
    font-size: $type-xs;
    margin-bottom: $spacing-2;
  }

  .custom-select {
    border-radius: $radius-3;
    max-height: 48px;
    ::v-deep.v-input__slot {
      min-height: 30px;
      padding: 0 16px !important;
    }
  }
  ::v-deep .v-menu__content {
    min-height: 96px;
    min-width: 96px !important;
    top: 216px !important;

  }
  .v-list {
    display: flex;
    flex-wrap: wrap;
    padding: 14px 14px 10px;
    min-height: 96px;
    min-width: 107px !important;
    max-width: 96px;
    ::v-deep &-item {
      margin-right: 8px;
      margin-bottom: 8px;
      &:last-child {
        margin-right: 0;
      }
      &:before {
        display: none;
      }
      flex: unset;
      padding: 0 !important;
      width: 16px;
      height: 16px;
      min-height: unset;
    }
  }
  ::v-deep .v-select .v-select__selections {
    flex-wrap: nowrap;
    padding: 6px 0 !important;
  }
  ::v-deep .v-input__append-inner {
    margin-top: 0;
    align-self: center;
  }
</style>
